import React, { useState } from "react";
import { useTranslator } from "../../hooks/useTranslator";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import SliderOne from '../../public/images/slider-1.png';
import SliderTwo from '../../public/images/slider-2.png';
import SliderThree from '../../public/images/slider-3.png';
import cx from "clsx";

export const Overview = () => {
    const { __T } = useTranslator();
    const [currentSlide, setCurrentSlide] = useState(0);

    const [refCallback, instanceRef] = useKeenSlider<HTMLDivElement>({
        slideChanged(slider) {
            setCurrentSlide(slider?.track?.details?.rel || 0);
        },
        breakpoints: {
            "(min-width: 400px)": {
                slides: { perView: 1, spacing: 5 },
            },
            "(min-width: 976px)": {
                slides: { perView: 2, spacing: 10 },
            },
        },
        loop: true
    })
    const handlePaginationButtonClick = (idx: number) => {
        instanceRef.current?.moveToIdx(idx);

    };


    return (
        <div className=" md:py-18 py-9 bg-[#F6FCFE] text-center px-3">
            <h3 className="text-black-100 md:text-5xl text-3xl font-extrabold my-11">{__T('Quick Overview')}</h3>
            <div ref={refCallback} className="keen-slider">
                <div className="keen-slider__slide h-92">
                    <img src={SliderOne.src} />

                </div>
                <div className="keen-slider__slide number-slide1">
                    <img src={SliderTwo.src} />

                </div>
                <div className="keen-slider__slide number-slide1">
                    <img src={SliderThree.src} />

                </div>
                {/* Bullet pagination */}
                <div
                    className={'align-center absolute bottom-0 z-10 flex w-full justify-center'}
                >
                    {Array.from(
                        Array(instanceRef?.current?.track?.details?.slides?.length).keys()
                    ).map((idx) => {
                        return (
                            <button
                                key={idx}
                                onClick={() => handlePaginationButtonClick(idx)}
                                className={cx(
                                    'm-1 inline-block h-4 w-4 rounded-full ',
                                    currentSlide === idx
                                        ? 'bg-brand-100'
                                        : 'bg-white border border-brand-100',

                                )}
                            />
                        );
                    })}
                </div>
            </div>
        </div>

    );
}