import React, { forwardRef, Ref, SVGProps } from "react";

const CallIconSVG = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
        <svg width="44" height="42" viewBox="0 0 44 42" fill="none" ref={ref} {...props}>
            <mask id="mask0_2_1487" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="42">
                <rect x="0.5" y="1.26489" width="43" height="40.0803" fill="currentColor" stroke="#707070" />
            </mask>
            <g mask="url(#mask0_2_1487)">
                <path d="M34.2729 18.3344H34.182V16.255C34.182 9.57533 28.3823 4.1604 21.2278 4.1604C14.0734 4.1604 8.27369 9.57533 8.27369 16.255V18.3344H7.72829C5.72833 18.3344 4.18294 19.9894 4.18294 21.8991V27.2037C4.13257 29.0547 5.69892 30.5933 7.6814 30.6403C7.69706 30.6407 7.71263 30.6409 7.72829 30.6411H11.0918C11.5682 30.6202 11.9363 30.2426 11.9139 29.7978C11.9131 29.7818 11.9118 29.7658 11.91 29.7499V19.3528C11.91 18.8436 11.5918 18.3343 11.0918 18.3343H10.0918V16.2549C10.0918 10.5128 15.0776 5.85781 21.2278 5.85781C27.3781 5.85781 32.3639 10.5128 32.3639 16.2549V18.3343H31.3639C30.8639 18.3343 30.5457 18.8435 30.5457 19.3528V29.7499C30.495 30.1927 30.8383 30.59 31.3126 30.6374C31.3296 30.6391 31.3468 30.6403 31.3639 30.641H32.4093L32.3184 30.7683C30.9545 32.4607 28.8143 33.4519 26.5458 33.4418C26.0492 31.1684 23.6726 29.7011 21.2375 30.1649C19.1552 30.5613 17.6538 32.264 17.637 34.2482C17.6619 36.5753 19.6897 38.4496 22.1823 38.4494C23.4163 38.4307 24.5924 37.958 25.4549 37.1338C26.0369 36.5774 26.4182 35.8653 26.5458 35.0968C29.3866 35.1069 32.0655 33.8641 33.7728 31.7443L34.6365 30.5561C36.5909 30.4288 37.8182 29.283 37.8182 27.6279V22.3233C37.8183 20.4987 36.3638 18.3344 34.2729 18.3344ZM10.0918 28.9436H7.72829C6.74954 28.9214 5.97541 28.1626 5.99925 27.2488C5.99968 27.2338 6.00029 27.2188 6.00107 27.2037V21.899C6.00107 20.923 6.72831 20.0318 7.72829 20.0318H10.0918V28.9436ZM24.1369 35.9457C23.6331 36.4557 22.9254 36.7477 22.1824 36.7521C20.7036 36.7301 19.5035 35.6284 19.4552 34.2482C19.4546 32.8654 20.6548 31.744 22.1359 31.7434C23.617 31.7429 24.8181 32.8634 24.8187 34.2462V34.2482C24.8565 34.8783 24.6091 35.4943 24.1369 35.9457ZM36.0001 27.6281C36.0001 28.7739 34.8183 28.9436 34.2729 28.9436H32.3639V20.0319H34.2729C35.2729 20.0319 36.0001 21.3474 36.0001 22.3234V27.6281Z" fill="currentColor" />
            </g>
        </svg>
    );
};
const ForwardRef = forwardRef(CallIconSVG);
export default ForwardRef;
