/* eslint-disable @next/next/no-img-element */
import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import { Button } from "../Ui/Button";
import Link from "next/link";
export const AboutUs = () => {
    const { __T } = useTranslator();

    return (
        <section className="bg-[#F6FCFE] py-11  mb-12" id="AboutUs">
            <div className="container justify-center m-auto mt-28">
                <div className="md:w-2/3 w-full m-auto md:flex md:gap-4">
                    <div className="md:w-1/3 w-full">
                        <h2 className="md:text-5xl text-3xl font-extrabold mx-auto tracking-normal leading-snug text-black-100 mb-10">
                            {__T('About us')}
                        </h2>
                    </div>
                    <div className="md:w-2/3 w-full text-center">
                        <p>
                            {__T('A technical system that brings all kinds of vehicle service centers together and makes it easier for Companies and Individuals to control vehicle budgets by converting paperwork into digital operations with accuracy and precision, which is documented with reports and figures to prevent manipulations.')}
                        </p>
                        <Button className="mt-2" buttonStyle={"outlinePrimary"} rounded="2xl">
                            <Link href={'/faq'} >
                                {__T('Learn more')}
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
}