/* eslint-disable @next/next/no-img-element */
import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import CheckIcon from "../Ui/Icons/homePage/CheckIcon";
import ChooseUsImg from '../../public/images/choos-kibreet.png';
import { Button } from "../Ui/Button";
export const HomeContent = () => {
    const { __T } = useTranslator();

    return (
        <>
            <section className="bg-[#F6FCFE] py-11 px-3">
                <div className="container justify-center m-auto md:mt-28 ">
                    <div className="w-full md:flex md:gap-4">
                        <div className="md:w-1/2 w-full">
                            <img src={ChooseUsImg.src} className="w-full h-[500px] mx-auto object-contain" alt="choose-us" />
                        </div>

                        <div className="md:w-1/2 w-full">
                            <h2 className="md:text-5xl text-3xl mx-auto tracking-normal ltr:leading-snug text-black-100 font-extrabold">
                                {__T('Why should you choose Kibreet?')}</h2>
                            <p className="text-gray-15 mt-2">
                                {__T('A fast and secure technology that allows you to save more than 25% of your vehicle’s oil budget. whatever your budget is, we can cover oil services for you and your fleet')}
                            </p>
                            <ul className="mt-5 leading-loose ">
                                <li className="text-black-100">
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100" /></span>
                                    {__T('Monitoring and scheduling the dates of changing your vehicle oils easily')}
                                </li>
                                <li>
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100" /></span>
                                    {__T('Follow-up and technical security system from any manipulation or fraud')}
                                </li>
                                <li>
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100" /></span>
                                    {__T('Special varieties and best prices at discounted prices')}
                                </li>
                                <li>
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100" /></span>
                                    {__T('The best service centers cover all over the KSA')}
                                </li> 
                                 <li>
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100" /></span>
                                    {__T('E-bill are reserved for audit at any time')}
                                </li>
                                <li>
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1 text-navy-100" /></span>
                                    {__T('24/7 support')}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full md:w-3/4 mx-auto flex mt-6 relative md:min-h-[200px]">
                        <div
                            className="w-full bg-[url('../public/images/signup-bg.png')] bg-no-repeat bg-cover bg-center p-9 text-center rounded-3xl md:absolute md:top-32">
                            <h3 className="text-white text-4xl">{__T('Sign up for a trial')}</h3>
                            <p className="text-white text-lg my-3">{__T('Create your free account now and get 30 days free trial no credit card required')}</p>
                            <Button buttonStyle={'basicText'} rounded="lg" className="bg-white mt-4 text-brand-100" type="button">{__T('Start Now')}</Button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}