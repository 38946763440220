import React, { forwardRef, Ref, SVGProps } from "react";

const CheckIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (

        <svg ref={ref} {...props} viewBox="0 0 22.952 22.952">
            <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle" d="M23.515,12.039A11.476,11.476,0,1,1,12.039.563,11.476,11.476,0,0,1,23.515,12.039Zm-12.8,6.077L19.226,9.6a.74.74,0,0,0,0-1.047L18.179,7.507a.74.74,0,0,0-1.047,0l-6.944,6.944L6.946,11.209a.74.74,0,0,0-1.047,0L4.852,12.256a.74.74,0,0,0,0,1.047l4.813,4.813a.74.74,0,0,0,1.047,0Z" transform="translate(-0.563 -0.563)" fill="currentColor" />
        </svg>
    );
};

const ForwardRef = forwardRef(CheckIcon);
export default ForwardRef;
