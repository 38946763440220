import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import CalendarIconSVG from "../Ui/Icons/homePage/CalendarIcon"
import CerttificateIconSVG from "../Ui/Icons/homePage/CerttificateIcon"
import RoadIconSVG from "../Ui/Icons/homePage/RoadIcon"
import TruckIconSVG from "../Ui/Icons/homePage/TruckIcon"
import CallIconSVG from "../Ui/Icons/homePage/CallIcon"

export const Features = () => {
    const { __T } = useTranslator();

    return (
        <>
            <section className="m-4 md:m-8 text-start mb-10 mt-10 md:mt-28 md:pt-12" id="features">
                <div className="container mx-auto grid justify-center gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="flex flex-col items-start p-4 md:p-11 hover:bg-brand-100 rounded-2xl bg-[url('../public/images/feature-item-bg.png')] bg-no-repeat bg-cover bg-center">
                        <div className="bg-brand-100 bg-opacity-5 rounded-xl underline underline-offset-1 decoration-brand-100">
                            <h3 className="text-white text-5xl font-bold">{__T('Our Features')}</h3>
                        </div>
                        <h3 className="my-3 text-3xl font-semibold  w-3/4 ltr:leading-snug text-brand-100">{__T('Work smarter with powerful features')}</h3>

                    </div>
                    <div className="flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl text-start">
                        <div className="bg-brand-100 bg-opacity-5 p-3 rounded-xl group-hover:bg-white">
                            <CalendarIconSVG className="w-8 h-8 text-brand-100" />
                        </div>
                        <h3 className="my-3 text-xl text-black-100 font-semibold">{__T('Monitoring and scheduling')}</h3>
                        <div className="space-y-1 text-gray-15 text-md ">
                            <p>{__T('Monitoring and scheduling the dates of changing your vehicle oils at the nearest service center easily With a variety of the best car oils in the world and a wide range of branches covering all parts of the KSA')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl text-start">
                        <div className="bg-brand-100 bg-opacity-5 p-3 rounded-xl group-hover:bg-white">
                            <CerttificateIconSVG className="w-8 h-8 text-brand-100" />
                        </div>
                        <h3 className="my-3 text-xl text-black-100 font-semibold">{__T('Special varieties and best prices')}</h3>
                        <div className="space-y-1 text-gray-15 text-md ">
                            <p>{__T('Get now All global motor oils with high quality services in the best service centers that provide to keep the manufacturers instructionswith discounted prices')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl min-h-[362px] text-start">
                        <div className="bg-brand-100 bg-opacity-5 p-3 rounded-xl group-hover:bg-white">
                            <RoadIconSVG className="w-8 h-8 text-brand-100" />
                        </div>
                        <h3 className="my-3 text-xl text-black-100 font-semibold">{__T('+1000 Service Center')}</h3>
                        <div className="space-y-1 text-gray-15 text-md ">
                            <p>{__T('More than 1,000 service centers covering all parts of the KSA to provide oil change services and motor oil filters with The highest quality and professional technicians')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl text-start">
                        <div className="bg-brand-100 bg-opacity-5 p-3 rounded-xl group-hover:bg-white">
                            <TruckIconSVG className="w-8 h-8 text-brand-100" />
                        </div>
                        <h3 className="my-3 text-xl text-black-100 font-semibold">{__T('Mobile Service vans')}</h3>
                        <div className="space-y-1 text-gray-15 text-md ">
                            <p>{__T('With the service Vans that comes to you at the place you specify It is an mobile integrated service center that provides car engine oil and engine filter change service')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col items-start p-4 md:p-11 shadow-box rounded-xl text-start">
                        <div className="bg-brand-100 bg-opacity-5 p-3 rounded-xl group-hover:bg-white">
                            <CallIconSVG className="w-8 h-8 text-brand-100" />
                        </div>
                        <h3 className="my-3 text-xl text-black-100 font-semibold">{__T('24/7 Support')}</h3>
                        <div className="space-y-1 text-gray-15 text-md">
                            <p>{__T('Customer service around the clock with a Periodic follow-up to Remind you to change the cars engine oil for each car and follow up on the services bills that have been made in centers and saved on the system')}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}