import React, { forwardRef, Ref, SVGProps } from "react";

const PlayIcon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (

        <svg ref={ref} {...props} viewBox="0 0 64 64">
            <g id="Group_922" data-name="Group 922" transform="translate(-814 -603)">
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(814 603)" fill="currentColor" opacity="0.142" />
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="23" cy="23" r="23" transform="translate(823 612)" fill="currentColor" />
                <path id="Icon_awesome-play" data-name="Icon awesome-play" d="M17.9,9.056,3.054.279A2.013,2.013,0,0,0,0,2.021v17.55a2.023,2.023,0,0,0,3.054,1.742L17.9,12.54A2.022,2.022,0,0,0,17.9,9.056Z" transform="translate(838.852 623.998)" fill="#fff" />
            </g>
        </svg>
    );
};

const ForwardRef = forwardRef(PlayIcon);
export default ForwardRef;
