import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import PlayIcon from "../Ui/Icons/homePage/PlayIcon"
import Link from "next/link";
export const Banner = () => {
    const { __T } = useTranslator();

    return (
        <div className="container  md:w-1/2 w-full mx-auto text-center pt-15 px-3">
            <h1 className="md:text-7xl text-4xl mx-auto tracking-normal md:leading-snug leading-normal md:rtl:leading-normal text-black-100">
                <span className="text-brand-100 font-extrabold uppercase">{__T('Kibreet')} </span>
            </h1>
            <p className="text-gray-15 text-xl leading-snug rtl:leading-normal">
                {__T('The best technical system in (KSA) to control the exchange of motor oils')}<br />
            </p>
            <div className="mx-auto text-center mt-11">
                <PlayIcon width={64} height={64} className="inline-block text-brand-100" /> <span className="underline text-xl">WATCH PROMO </span>
            </div>
            <div className="md:h-[500px] h-[300px] mt-11 bg-[url('../public/images/dashboard.png')] bg-no-repeat bg-contain md:bg-center bg-top relative">
                <div className="absolute bottom-0 w-full flex">
                    <Link className="mx-auto py-3 px-4 bg-brand-100 rounded-md text-white mt-2 text-lg" href={`/#pricing`} scroll={false}> {__T('save more than 25% of your vehicle’s engine oil budget')}</Link>
                </div>
            </div>
        </div>
    );
}