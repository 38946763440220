import React, { forwardRef, Ref, SVGProps } from "react";

const GrowthIconSVG = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
    
        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="42" viewBox="0 0 44 42" fill="none" ref={ref} {...props}>
        <mask id="mask0_4_1815"  maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="42">
          <rect x="0.5" y="0.5" width="43" height="40.0803" fill="#C02C4A" stroke="#707070"/>
        </mask>
        <g mask="url(#mask0_4_1815)">
          <path d="M0.48 29.12C0.8 29.44 1.2 29.6 1.6 29.6C2 29.6 2.4 29.44 2.72 29.12L9.2 22.64L13.28 26.72C13.92 27.36 14.88 27.36 15.52 26.72L36.8 5.44V8.8C36.8 9.68 37.52 10.4 38.4 10.4C39.28 10.4 40 9.68 40 8.8V1.6C40 0.72 39.28 0 38.4 0H31.2C30.32 0 29.6 0.72 29.6 1.6C29.6 2.48 30.32 3.2 31.2 3.2H34.56L14.4 23.36L10.32 19.28C9.68 18.64 8.72 18.64 8.08 19.28L0.48 26.88C-0.16 27.52 -0.16 28.48 0.48 29.12Z" fill="#C02C4A"/>
          <path d="M35.2 36.8V20.8C35.2 18.56 33.44 16.8 31.2 16.8C28.96 16.8 27.2 18.56 27.2 20.8V36.8C27.2 39.04 28.96 40.8 31.2 40.8C33.44 40.8 35.2 39.04 35.2 36.8ZM30.4 36.8V20.8C30.4 20.32 30.72 20 31.2 20C31.68 20 32 20.32 32 20.8V36.8C32 37.28 31.68 37.6 31.2 37.6C30.72 37.6 30.4 37.28 30.4 36.8ZM25.6 36.8V30.4C25.6 28.16 23.84 26.4 21.6 26.4C19.36 26.4 17.6 28.16 17.6 30.4V36.8C17.6 39.04 19.36 40.8 21.6 40.8C23.84 40.8 25.6 39.04 25.6 36.8ZM20.8 36.8V30.4C20.8 29.92 21.12 29.6 21.6 29.6C22.08 29.6 22.4 29.92 22.4 30.4V36.8C22.4 37.28 22.08 37.6 21.6 37.6C21.12 37.6 20.8 37.28 20.8 36.8ZM16 36.8V34.4C16 32.16 14.24 30.4 12 30.4C9.75999 30.4 7.99999 32.16 7.99999 34.4V36.8C7.99999 39.04 9.75999 40.8 12 40.8C14.24 40.8 16 39.04 16 36.8ZM11.2 36.8V34.4C11.2 33.92 11.52 33.6 12 33.6C12.48 33.6 12.8 33.92 12.8 34.4V36.8C12.8 37.28 12.48 37.6 12 37.6C11.52 37.6 11.2 37.28 11.2 36.8ZM7.99999 8H16.8C17.68 8 18.4 7.28 18.4 6.4C18.4 5.52 17.68 4.8 16.8 4.8H7.99999C7.11999 4.8 6.39999 5.52 6.39999 6.4C6.39999 7.28 7.11999 8 7.99999 8ZM7.99999 3.2H11.2C12.08 3.2 12.8 2.48 12.8 1.6C12.8 0.72 12.08 0 11.2 0H7.99999C7.11999 0 6.39999 0.72 6.39999 1.6C6.39999 2.48 7.11999 3.2 7.99999 3.2ZM22.96 5.52C22.88 5.44 22.88 5.36 22.8 5.28C22.16 4.72 21.12 4.72 20.56 5.28C20.48 5.36 20.4 5.44 20.4 5.52C20.32 5.6 20.32 5.68 20.24 5.84C20.16 5.92 20.16 6 20.16 6.16V6.8C20.16 6.88 20.24 6.96 20.24 7.12C20.24 7.2 20.32 7.28 20.4 7.36C20.48 7.44 20.48 7.52 20.56 7.6C20.8 7.84 21.2 8 21.6 8C22 8 22.4 7.84 22.72 7.52C22.8 7.44 22.88 7.36 22.88 7.28C22.96 7.2 22.96 7.12 23.04 7.04C23.12 6.96 23.12 6.88 23.12 6.72V6.08C23.12 6 23.04 5.92 23.04 5.76C23.04 5.68 22.96 5.6 22.96 5.52Z" fill="#C02C4A"/>
        </g>
      </svg>
    );
};

const ForwardRef = forwardRef(GrowthIconSVG);
export default ForwardRef;
