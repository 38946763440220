import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import GrowthIconSVG from "../Ui/Icons/homePage/GrowthIcon";
import OrdersIconSVG from "../Ui/Icons/homePage/OrdersIcon";
import PlatformIconSVG from "../Ui/Icons/homePage/PlatformIcon";
import CallIconSVG from "../Ui/Icons/homePage/CallIcon";

export const Succeed = () => {
    const { __T } = useTranslator();

    return (
        <section className="container mx-auto text-center mb-10">
            <h2 className="md:text-5xl text-3xl font-extrabold mx-auto tracking-normal ltr:leading-snug text-black-100 mb-10">
                {__T('We Can Help You Succeed')}
            </h2>

            <div className="container mx-auto grid justify-center gap-4 sm:grid-cols-2 lg:grid-cols-4">
                <div className="flex flex-col items-center p-4 md:p-11 hover:bg-bleed-100 hover:rounded-2xl group">
                    <div className="bg-bleed-100 bg-opacity-5 p-3 rounded-xl group-hover:bg-white">
                        <GrowthIconSVG className="w-8 h-8 text-bleed-100" />
                    </div>
                    <h3 className="my-3 text-xl text-black-100 font-semibold group-hover:text-white">{__T('Sales Growth')}</h3>
                    <div className="space-y-1 text-gray-15 text-md text-center group-hover:text-white">
                        <p>{__T('Increase your service centers sales of Motor Engine Oils By addingthe products , services and price for them , your branches location')}</p>
                    </div>
                </div>
                <div className="flex flex-col items-center p-4 md:p-11 hover:bg-navy-100 hover:rounded-2xl group">
                    <div className="bg-navy-100 bg-opacity-5 p-3 rounded-xl group-hover:bg-white">
                        <OrdersIconSVG className="w-8 h-8 text-navy-100" />
                    </div>
                    <h3 className="my-3 text-xl text-black-100 font-semibold group-hover:text-white">{__T('Unlimited orders')}</h3>
                    <div className="space-y-1 text-gray-15 text-md text-center group-hover:text-white">
                        <p>{__T('As soon as you finish registration in Kibreet system your service centers will be able to receive change oil orders in a scheduled manner with More than 10,000 user')}</p>
                    </div>
                </div>
                <div className="flex flex-col items-center p-4 md:p-11 hover:bg-bleed-100 hover:rounded-2xl group">
                    <div className="bg-bleed-100 bg-opacity-5 p-3 rounded-xl group-hover:bg-white">
                        <PlatformIconSVG className="w-8 h-8 text-bleed-100" />

                    </div>
                    <h3 className="my-3 text-xl text-black-100 font-semibold group-hover:text-white">{__T('Easy technical platform')}</h3>
                    <div className="space-y-1 text-gray-15 text-md text-center group-hover:text-white">
                        <p>{__T('A technical platform with Easy interface which you can add all items and prices, services price and you can make discounts on specific types and increase sales opportunities inside & outside your area by using the requests on our service van’s')}</p>
                    </div>
                </div>
                <div className="flex flex-col items-center p-4 md:p-11 hover:bg-navy-100 hover:rounded-2xl group">
                    <div className="bg-navy-100 bg-opacity-5 p-3 rounded-xl group-hover:bg-white">
                        <CallIconSVG className="w-8 h-8 text-navy-100" />
                    </div>
                    <h3 className="my-3 text-xl text-black-100 font-semibold group-hover:text-white">{__T('24/7 Support')}</h3>
                    <div className="space-y-1 text-gray-15 text-md text-center group-hover:text-white">
                        <p>{__T('Customer service around the clock are ready to receive inquiries at any time, with a technical support team available to answer all system questions and train your team on how to use the system')}</p>
                    </div>
                </div>
            </div>
        </section>
    );
}