/* eslint-disable @next/next/no-img-element */
import React from "react";
import { useTranslator } from "../../hooks/useTranslator";
import CheckIcon from "../Ui/Icons/homePage/CheckIcon";
import ChooseUsImg from '../../public/images/choos-kibreet.png'
export const ChooseUs = () => {
    const { __T } = useTranslator();

    return (
        <section className="bg-[#F6FCFE] py-11 px-3" id="chooseUs">
            <div className="container justify-center m-auto mt-28 ">
                <div className="w-full md:flex md:gap-4">
                    <div className="md:w-1/2 w-full">
                        <img src={ChooseUsImg.src} className="md:w-2/3 w-full mx-auto" alt="choose us" />
                    </div>
                    <div className="md:w-1/2 w-full">
                        <div className="md:w-2/3 w-full">
                            <h2 className="md:text-5xl text-3xl mx-auto tracking-normal ltr:leading-snug text-black-100 font-extrabold leading-normal">
                                {__T('Increase your business growth & sales')}</h2>
                            <p className="text-gray-15">
                                {__T('Register now on our platform for selling motor oils, add your services, double the sales of your service centers, and increase your chances of reaching the customer in an easy and fast way.')}
                            </p>
                            <ul className="mt-5 leading-loose ">
                                <li className="text-black-100">
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1" /></span>
                                    {__T('Easy and fast user interface')}
                                </li>
                                <li>
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1" /></span>
                                    {__T('Saved invoices for services and sold engine oils ')}
                                </li>
                                <li>
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1" /></span>
                                    {__T('Technical training on how to use the system with follow-up')}
                                </li>
                                <li>
                                    <span> <CheckIcon width={14} height={14} className="inline-block ltr:mr-1 rtl:ml-1" /></span>
                                    {__T('completely digital transactions')}
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    );
}